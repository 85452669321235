<template>
  <SubPageWrapper width="full">
    <template #breadcrumbs>
      <SlBreadcrumb @click="goToDemand">
        {{ $t('Main.tabNames.main') }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ $t('ViewPromo.Ui.:Caption') }}
      </SlBreadcrumb>
    </template>
    <template #filter>
      <SlBadge
        v-if="!isRootNode"
        :close-tooltip="getTooltip($t('Web.Filters.RemoveFilter'))"
        closable
        @close="handleCloseFilter"
      >
        <template #text>
          <span class="subpage__filter-name">
            {{ $t('Web.Filters.FilteredByNew') }}
          </span>
          <SlNodePath
            :path="parsedPath"
            :highlight-target="false"
          />
        </template>
      </SlBadge>
    </template>
    <ContentBlock>
      <div class="subpage__actions">
        <SlSearchInput
          :value="search"
          class="w-280"
          @submit="handleSearch"
        />
        <div class="subpage__actions-group">
          <SlDropdown
            :target-label="$t('Web.InTransition.BtnExportImport')"
            placement="bottom-end"
          >
            <template #content>
              <SlDropdownOption
                select-close
                @select="handleExportToXlsx"
              >
                {{ $t('Main.Ui.acChooseExportToXlsx') }}
              </SlDropdownOption>
              <div
                v-tooltip.bottom="getTooltip({
                  content: $t('Web.Promotions.ImportUnavailable'),
                  disabled: isRootNode
                })"
              >
                <SlDropdownOption
                  :disabled="!isRootNode"
                  select-close
                  @select="handleImportFromXlsx"
                >
                  {{ $t('Web.InTransition.ImportFromXLSX') }}
                </SlDropdownOption>
              </div>
            </template>
          </SlDropdown>
        </div>
      </div>
      <div class="subpage__table-wrapper">
        <div class="subpage__table">
          <SlTable
            id="promotions"
            :headers="tableHeaders"
            :rows="tableRows"
            header-unique-key="key"
            header-name-key="name"
            header-type-key="type"
            unique-key="id"
            :max-height="1000"
            :value-parser="parseValue"
            :loading="loading"
            :initial-col-sizes="[180, 350, 200]"
            api-sortable
            :sort-by="colClass"
            :sort-order="sortOrder"
            :total-rows-count="totalRowsCount"
            :current-page="currentPage"
            :per-page="perPage"
            :inner-no-data="!!search"
            pagination
            num-col
            @page-change="handlePaginationChange"
            @per-page-change="handlePerPageChange"
            @sort="handleSort"
            @contextmenu:clipboard="(cb) => cb()"
          >
            <template #loader>
              <SlOverlay :show="loading" />
            </template>

            <template #itemcode="slotScope">
              <div class="sl-table__cell-text">
                {{ slotScope.text }}
              </div>
            </template>
            <template #start="slotScope">
              <div :class="['sl-table__cell-text', [`color--${getForeground(slotScope)}`]]">
                {{ getDateValue(slotScope.cellValue) }}
              </div>
            </template>
            <template #end="slotScope">
              <div :class="['sl-table__cell-text', [`color--${getForeground(slotScope)}`]]">
                {{ getDateValue(slotScope.cellValue) }}
              </div>
            </template>
            <template #discount="slotScope">
              <div :class="['sl-table__cell-text', [`color--${getForeground(slotScope)}`]]">
                {{ slotScope.text }}
              </div>
            </template>

            <template #no-data>
              <SlNoData>
                <template #image>
                  <icon
                    :data="noDataMeta.icon"
                    :class="{
                      'illustration-md': !search,
                      'size-48': search,
                      'fill-off': search
                    }"
                  />
                </template>
                <template #title>
                  {{ noDataMeta.title }}
                </template>
                <template #text>
                  {{ noDataMeta.text }}
                </template>
              </SlNoData>
            </template>
          </SlTable>
        </div>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { fileSaver } from '@/mixins/webAPI';
import { tableConfig } from '@/mixins/tableConfig';
import { routeNames } from '@/config/router/router.config';
import { ROOT_NODE_ID } from '@/config/demand/tree/tree.config';
import modalIds from '@/config/shared/modalsId.config';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'Promotions',
  mixins: [tableConfig, fileSaver],
  data() {
    return {
      getTooltip,
      ROOT_NODE_ID,
      loading: false
    };
  },
  computed: {
    ...mapState({
      tree: (state) => state.demand.tree.tree,
      activeNodePath: (state) => state.demand.tree.active_node_path,
      table: (state) => state.demand.promotion.promotions || [],
      tableConfig: (state) => state.demand.promotion.tableConfig
    }),
    ...mapGetters({
      activeNodeId: 'demand/tree/activeNodeId'
    }),
    tableHeaders() {
      return this.table.headers || [];
    },
    tableRows() {
      return this.table.rows || [];
    },
    totalRowsCount() {
      return this.table.totalRowsCount;
    },
    currentPage() {
      return this.table.currentPage;
    },
    isRootNode() {
      return this.activeNodeId === ROOT_NODE_ID;
    },
    parsedPath() {
      return this.activeNodePath
        .slice(1)
        .map((node) => node.text);
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.NoData.DefaultTitle')
      };
    }
  },
  beforeMount() {
    this.fetchTable();
  },
  methods: {
    ...mapActions({
      fetchPromotions: 'demand/promotion/fetchPromotions',
      updateTableConfig: 'demand/promotion/updateTableConfig',
      importFromXlsx: 'demand/promotion/importFromXlsx',
      exportToXlsx: 'demand/promotion/exportToXlsx',
      fetchTree: 'demand/tree/fetchTree',
      setActiveNode: 'demand/tree/setActiveNode'
    }),
    async fetchTable() {
      try {
        this.loading = true;

        if (!this.tree?.length) {
          await this.fetchTree();
        }

        await this.fetchPromotions();
      } finally {
        this.loading = false;
      }
    },
    goToDemand() {
      this.$router.push({
        name: routeNames.DEMAND
      });
    },
    // table configuration
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue?.val || cellValue?.auto || '';
    },
    getDateValue(cellValue) {
      const value = cellValue?.val || cellValue?.auto || '';
      const dateString = dateByLocaleKey(value, localeDateKeys.YMD);

      return dateString || '';
    },
    async handleCloseFilter() {
      await this.setActiveNode({
        node: this.tree[0],
        index: 0
      });

      this.fetchTable();
    },
    getForeground(scope) {
      if (scope.cellValue?.val) {
        return 'over';
      }

      return '';
    },
    handlePaginationChange(page) {
      this.page = page;

      this.fetchTable();
    },
    handlePerPageChange(pageSize) {
      this.perPage = pageSize;
      this.page = 1;

      this.fetchTable();
    },
    async handleSort({ callback }) {
      const { key, order } = callback();

      this.sortOrder = order;
      this.colClass = key;

      try {
        this.loading = true;

        await this.setSortingParams({
          config: this.tableConfig,
          params: {
            table: 'promotions'
          }
        });
      } finally {
        this.loading = false;
      }
    },
    handleSearch(query) {
      if (!this.search && !this.totalRowsCount) {
        return;
      }

      this.search = query;

      this.fetchTable();
    },
    // export/import
    handleExportToXlsx() {
      this.saveFile(this.exportToXlsx);
    },
    handleImportFromXlsx() {
      this.showModal(modalIds.SL_UPLOAD_FILE, {
        title: this.$t('Web.InTransition.ImportFile'),
        uploadHandler: this.importFromXlsx
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/mixins";
@import "@/style/utils/color";

.subpage__table-wrapper {
  height: calc(100% - 40px);
}

.sl-badge {
  max-width: fit-content;

  ::v-deep .sl-badge__text {
    display: flex;
    align-items: center;
    gap: 4px;

    .sl-node-path__text {
      color: $grey-80;
      @include body-3-md;
    }
  }
}
</style>
